.section--showroom {
  background-color: $color-mitsubishi-charcoal !important;

  &:first-child {
    padding-bottom: 3rem;
  }

  .content-block {
    padding-top: 1.5rem;

    &:last-child {
      padding-bottom: 3rem;
    }
  }

  .content-block__section {
    padding-top: 1.5rem;
  }

  .richtext {
    h1, h2, h3, h4, h5 {
      color: $color-white;
    }

    color: $color-white;
  }
}

.feature-card, .card {
  & .richtext {
    h1, h2, h3, h4, h5 {
      color: $header-color;
    }

    color: $color-black;
  }
}

.section--showroom .fineprint--grey {
  color: $color-mitsubishi-charcoal--light;
}

@media (max-width: $screen-medium) {
  .section--showroom {
    &:first-child {
      padding-bottom: 2rem;
    }

    .content-block {
      padding-top: 1rem;

      &:last-child {
        padding-bottom: 2rem;
      }
    }

    .content-block__section {
      padding-top: 1rem;
    }
  }
}